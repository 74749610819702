@media screen and (max-width: 400px) {
    #features {
        padding: 20px;
        width: 111%;
    }
    #about,
    #services,
    #testimonials,
    #team,
    #contact,
    #footer {
        width: 111%;
    }

    #portfolio {
        width: 110%;
    }
}

.app-notification {
    position: absolute;
    background-color: #4760ff;
    padding: 8px;
    width: 100%;
    color: #fff;
}

.app-notification p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

#language_btn {
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    color: #555;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 2px;
    border-radius: 0;
    margin: 9px 20px 0;
}
